import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiOrderPlayFill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { Input } from '../../Components/Form/Input'
import { useForm } from 'react-hook-form'

export interface MidiaProps {
  id: string
  title: string
  short_description: string
  link: string
  cover?: string
  author?: string
  slug?: string
  order: string

}

const MidiaList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<MidiaProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as MidiaProps)
  const toast = useToast()

  const { register, getValues } = useForm()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/midia', {
        params: {
          pag
        }
      })

      // const normalize = data?.data?.map((post: any) => {
      //   return {
      //     ...post,
      //     created_at: format(new Date(post.created_at), 'dd/MM/yyyy')
      //   }
      // })

      setPage(pag)
      setData(data?.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar os  posts: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/midia/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as MidiaProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as MidiaProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as MidiaProps)
  }, [])

  const handleOrder = useCallback(async () => {
    try {
      const values = getValues()
      const order = FindKeyWithPrefix(values, 'order-')

      const midias = data?.map(i => {
        const newOrder = order.find((item: any) => item.key === `order-${i.id}`)

        return {
          midia_id: i.id,
          order: newOrder?.value || 999
        }
      })
      console.log('midias: ', midias)

      await api.post('/midia-order', { midias })
      load(page)
    } catch (error) {

    }
  }, [data, getValues, page, load])

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Post"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="green.dark">
              Mídia
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            {/* <SearchBox placeholder="Buscar por título" /> */}

            <Flex>
            <Button
              onClick={handleOrder}
              size="sm"
              fontSize="sm"
              bg="green.dark"
              mr="10px"
              _hover={{ bgColor: 'green.light' }}
              color="white"
              leftIcon={<Icon as={RiOrderPlayFill} fontSize="16" />}
            >
              Salvar Ordenação
            </Button>

            <Button
              as="a"
              href="/midia/create"
              size="sm"
              fontSize="sm"
              bg="green.dark"
              _hover={{ bgColor: 'green.light' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar nova Mídia
            </Button>
            </Flex>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de notícias</Text>
            </Flex>
                )
              : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="left">Título</Th>
                    <Th textAlign="center">Autor</Th>
                    <Th textAlign="center">Ordem</Th>
                    {/* <Th textAlign="center">Data</Th> */}
                    {/* <Th textAlign="center">Departamento</Th>
                    <Th textAlign="center">Cargo</Th>
                    <Th textAlign="center">Email</Th> */}

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.title}>

                      <Td>
                          <Link color="wine.500" textAlign="left">
                            <Text fontWeight="bold" color="green.dark">{i.title}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color="green.dark">{i.author}</Text>
                          </Link>
                      </Td>
                      <Td>
                        <Input

                          placeholder="Preencha a ordem"
                          // errors={errors.name}
                          style={{ width: 80, height: 30, fontSize: 13, textAlign: 'center', fontWeight: 500 }}
                          {...register(`order-${i.id}`)}
                          defaultValue={i.order}

                        />
                      </Td>
                      <Td>
                        <Flex>
                        {/* {isWideScreen
                          ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                              onClick={() => setDeactivatedItem(i)}
                            >
                              Desativar
                            </Button>
                            )
                          : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="desativar"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                            )} */}

                          {isWideScreen
                            ? (
                            <Button
                            as="a"
                            href={`/midia/create/${i?.slug}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="green.dark"
                              // colorScheme="green"
                              color="green.light"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}

                              size="sm"
                              fontSize="sm"
                              bg="green.teal"
                              color="green.dark"
                              // colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              // onClick={() => setSelectedItem(i)}
                              onClick={() => {}}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
                )}
        </Box>
      </Flex>
    </Box>
  )
}

export { MidiaList }
