import { Flex, useBreakpointValue, IconButton, Icon, Box } from '@chakra-ui/react'
import { Logo } from './Logo'
import { SearchBox } from './SearchBox'
import { NotificationsNav } from './NotificationsNav'
import { Profile } from './Profile'
import { useSideBar } from '../../Hooks/SideBarContext'
import { RiMenuLine } from 'react-icons/ri'
import { useAuth } from '../../Hooks/Auth'
import React, { useCallback, useEffect, useState } from 'react'
import { ResetPasswordModal } from '../ResetPasswordModal'

export function Header () {
  const [showModalResetPass, setShowModalResetPass] = useState(false)
  const { onOpen } = useSideBar()
  const { user } = useAuth()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  useEffect(() => {
    if (Number(user?.resetPass) === 1) {
      setShowModalResetPass(true)
    }
  }, [user])

  return (
    <Flex
      as="header"
      maxWidth={1480}
      w="100%"
      h="20"
      mx="auto"
      mt="4"
      px="6"
      align="center"
      mb="70px"
    >

{showModalResetPass && (
        <ResetPasswordModal
          title="Redefinir Senha"
          isOpen={showModalResetPass}
          cancelFunction={() => {}}
          nextFunction={() => setShowModalResetPass(false)}
        />
)}

      {isWideScreen === false && (
        <IconButton
          aria-label="open navigaion"
          onClick={onOpen}
          variant="unstyled"
          icon={<Icon as={RiMenuLine} fontSize="24" />}
          mr="2"
        />
      )}

        <Logo />

      {/* {isWideScreen && <SearchBox />}  */}

      <Flex align="center" ml="auto">
        <NotificationsNav />
        <Profile showProfileData={isWideScreen} />
      </Flex>
    </Flex>
  )
}
