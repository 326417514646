import React, {
  FC,
  createContext,
  useCallback,
  useState,
  useContext
} from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../Services/api'
import { useToast } from '@chakra-ui/react'

export interface UserProps {
  id: string
  name: string
  email: string
  department?: string
  resetPass: string
  roles: string[]
  permissions: string[]
}

interface AuthData {
  token: string
  user: UserProps
  // config: ConfigProps;
}

interface SignInProps {
  login: string
  password: string
}

interface AuthProps {
  user: UserProps

  signIn: (credentials: SignInProps) => Promise<void>
  signOut: () => void
  updateGlobalUser: (data: any) => Promise<void>
}
const AuthContext = createContext<AuthProps>({} as AuthProps)

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const Client = '@Mydoor'
  const toast = useToast()
  const navigate = useNavigate()

  // const history = useHistory();
  const [data, setData] = useState<AuthData>((): any => {
    const token = localStorage.getItem(`${Client}:token`)
    const user = localStorage.getItem(`${Client}:user`)

    if (token && user) {
      api.defaults.headers.common.authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) }
    }

    return {} as AuthData
  })

  const signIn = useCallback(
    async ({ password, login }: SignInProps) => {
      try {
        const response = await api.post('/collaborators/login', { password, login })
        const { token, Collaborator } = response.data
        api.defaults.headers.common.authorization = `Bearer ${token}`

        // remover depois
        // const token = 'fake-token'
        // const user = {
        //   id: 'fake-id',
        //   name: 'Diego Hennrich'
        // }
        const roles = ['all.list']
        const permissions = ['all.list']

        const newUser = {
          ...Collaborator,
          roles,
          permissions
        }

        localStorage.setItem(`${Client}:token`, token)
        localStorage.setItem(`${Client}:user`, JSON.stringify(newUser))

        setData({ token, user: newUser })
        navigate('/dashboard')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'yellow'

        })
      }
    },
    [navigate, toast]
  )

  const signOut = useCallback((): void => {
    localStorage.removeItem(`${Client}:token`)
    localStorage.removeItem(`${Client}:user`)

    setData({} as AuthData)

    navigate('/')
  }, [navigate])

  const updateGlobalUser = useCallback(
    async (dados: UserProps) => {
      try {
        const newUser = {
          ...data.user,
          ...dados
        }

        localStorage.setItem(`${Client}:user`, JSON.stringify(newUser))

        setData(prev => ({ token: prev.token, user: newUser }))
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'yellow'

        })
      }
    },
    [toast, data]
  )

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateGlobalUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthProps => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used inside an AuthProvider')
  }

  return context
}
