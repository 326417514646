import React, { FC } from 'react'
import { Flex, Box, Avatar, Text } from '@chakra-ui/react'
import { adminVersion } from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'

interface ProfileProps {
  showProfileData?: boolean
}
const Profile: FC<ProfileProps> = ({ showProfileData = true }) => {
  const { user } = useAuth()
  return (
        <Flex align="center">
            {showProfileData && (
                 <Box
                 mr="4" textAlign="right">
                 <Text color="white">{user?.name}</Text>
                 <Text color="white" fontSize="small">
                 {user?.email}
                 </Text>
                 <Text color="white" fontSize="small">Versão: {adminVersion}</Text>
             </Box>
            )}

        <Avatar size="md" name={user?.name}/>
    </Flex>
  )
}

export { Profile }
