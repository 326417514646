import { Flex, Input, Icon } from '@chakra-ui/react'
import { RiSearch2Line } from 'react-icons/ri'
import React, { useState, useEffect, FC } from 'react'

interface Props {
  placeholder?: string
  onSearch: (data: { page: number; search: string }) => Promise<void>
}

const SearchBox: FC<Props> = ({ placeholder, onSearch }) => {
  const [value, setValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)

    if (typingTimeout != null) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(
      setTimeout(() => {
        onSearch({ page: 1, search: newValue.length >= 3 ? newValue : '' })
      }, 500)
    )
  }

  return (
    <Flex
      as="label"
      flex="1"
      py="4"
      px="8"
      ml="6"
      maxWidth={400}
      alignSelf="center"
      color="white"
      position="relative"
      bg="green.dark"
      borderRadius="full"
    >
      <Input
        name="search"
        color="white"
        variant="unstyled"
        px="4"
        mr="4"
        placeholder={placeholder}
        _placeholder={{ color: 'white' }}
        value={value}
        onChange={handleChange}
      />
      <Icon as={RiSearch2Line} fontSize="20" />
    </Flex>
  )
}

export { SearchBox }
