/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  SimpleGrid,
  VStack,
  Text,
  Flex,
  Toast,
  useToast
} from '@chakra-ui/react'
import { Select } from '../../../Components/Form/Select'
import { useForm } from 'react-hook-form'
import { Input } from '../../../Components/Form/Input'
import { MoneyInput } from '../../../Components/Form/MoneyInput'
import { SelectWithSearch } from '../../../Components/Form/SelectWithSearch'

import api from '../../../Services/api'
import { TextArea } from '../../../Components/Form/TextArea'
import { DragAndDrop, DragAndDropExportedProps } from '../../../Components/Form/DragAndDrop'
import { InputMaskCustom } from '../../../Components/Form/InputMask'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
}

interface BankProps {

  bank: string | null
  agency: string | null
  account: string | null
  pix: string | null
}

type TypeSelectedProps = 'TAX' | 'REFUND' | undefined

const ModalNewBill: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  description,
  cancelText,
  nextText,
  cancelScheme,
  nextScheme,
  thirdButton,
  thirdButtonText,
  thirdButtonFunction
}) => {
  const cancelRef = React.useRef<any>()
  const [providers, setProviders] = useState<any[]>([])
  const [providerSelected, setProviderSelected] = useState('')
  const [bankData, setBankData] = useState<BankProps>({} as BankProps)
  const [typeSelected, setTypeSelected] = useState<TypeSelectedProps>(undefined)
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const toast = useToast()

  const PAYMENTS_TYPE_OPTIONS = [
    {
      label: 'Taxa',
      value: 'TAX'
    },
    {
      label: 'Reembolso',
      value: 'REFUND'
    }
  ]

  const SERVICE_OPTIONS = [
    {
      label: 'Taxa de alvará de demolição',
      value: '1'
    },
    {
      label: 'Despesa com alimentação',
      value: '2'
    },
    {
      label: 'Despesa com hospedagem',
      value: '3'
    },
    {
      label: 'Despesa com transporte',
      value: '4'
    },
    {
      label: 'DEspesa com km',
      value: '5'
    }
  ]

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })

  const handleSubmitForm = useCallback((values: any) => {
    console.log('values: ', values)

    const currentFile = DragRef?.current?.selectedFile

    if (!currentFile) {
      toast({
        title: 'Anexo é Obrigatório.',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadShowProvider = useCallback(async (provider_id: string) => {
    try {
      const { data } = await api.get(`/providers/${provider_id}`)

      console.log('data loadShowProvider: ', data)

      setBankData(data?.bank)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectProvider = useCallback((provider_id: any) => {
    console.log('provider selecionado: ', provider_id)
    loadShowProvider(provider_id)
    const provider = providers.find(i => i.value === provider_id)

    if (provider != null) {
      setProviderSelected(provider.label)
    }
  }, [loadShowProvider, providers])

  const loadProviders = useCallback(async () => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0 // somente prestador ativo
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    loadProviders()
  }, [])

  const handleSelectType = useCallback((e: any) => {
    setTypeSelected(e?.target?.value)
  }, [])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="6xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <Box
            as="form"
                onSubmit={handleSubmit(handleSubmitForm)}

            flex="1"
            borderRadius={8}
            bg="white"
            p="8"
            >
            <AlertDialogBody>
                <VStack>
                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mb="20px">

                <Select
                    label="Tipo da Conta"
                    placeholder="Selecionar Tipo"
                    // errors={errors.gender}
                    {...register('type')}
                    options={PAYMENTS_TYPE_OPTIONS}
                    onChange={handleSelectType}
                    />

                    <Select
                    label="Serviço Prestado"
                    placeholder="Selecionar Serviço"
                    // errors={errors.gender}
                    {...register('service')}
                    options={SERVICE_OPTIONS}
                    />

                     <MoneyInput

                    label="Valor Nominal"
                    placeholder="Preencha o valor"
                    // errors={errors.name}
                    {...register('value')}
                    />

                    </SimpleGrid>

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                    {typeSelected === 'TAX'
                      ? (
    <>

     <InputMaskCustom
                  type="text"
                  label="Data de Vencimento"
                  // errors={errors.born}
                  {...register('validate')}
                  placeholder="Digite data de vencimento"
                  mask="99/99/9999"
                />

    <Input
    label='Código de Barras / Pix Copia e Cola'
      {...register('bar_code')}
    />
     </>

                        )
                      : <Box> <Text color="wine.primary" fontWeight={500} align="left" ></Text></Box>}

                      </SimpleGrid>

                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                        <DragAndDrop
                            label="Anexo"
                            ref={DragRef}
                            containerStyle={{ margin: 'left' }}
                          />

                    </SimpleGrid>

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="150px">
                        <SelectWithSearch
                            label="Prestador"
                            title={providerSelected ? 'Trocar Prestador' : 'Selecionar Prestador'}
                            options={providers}
                            extraFunction={(e: any) => handleSelectProvider(e)}

                            {...register('provider')}

                        />

    {providerSelected && (
    <Box>
    <Text color="wine.primary" fontWeight={500} align="center">Nome</Text>
    <Text align="center">{providerSelected}</Text>
    </Box>
    )}

                {bankData?.pix && (
                    <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Chave Pix</Text>
                    <Text align="center">{bankData?.pix}</Text>
                </Box>
                )}

                {bankData?.bank && (
                <>
                    <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Banco</Text>
                    <Text align="center">{bankData?.bank}</Text>
                    </Box>

                    <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Agência</Text>
                    <Text align="center">{bankData?.agency}</Text>
                    </Box>

                    <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Conta</Text>
                    <Text align="center">{bankData?.account}</Text>
                    </Box>

                </>

                )}

                    </SimpleGrid>

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                <TextArea
                    label="Observação"
                    placeholder="Preencha a observação"
                    {...register('obs')}
                />

                    </SimpleGrid>

                </VStack>

            </AlertDialogBody>
            <AlertDialogFooter>
            {thirdButton && (
              <Button onClick={thirdButtonFunction}>
                {thirdButtonText || 'Cancelar'}
              </Button>
            )}

            <Button colorScheme={cancelScheme} onClick={cancelFunction}>
              {cancelText || 'Cancelar'}
            </Button>
            <Button
                type='submit'
              colorScheme="blue"

              ml={3}
            >
              Solicitar Pagamento
            </Button>
          </AlertDialogFooter>
          </Box>

        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { ModalNewBill }
function toast (arg0: { title: any; position: string; isClosable: boolean; status: string; variant: string }) {
  throw new Error('Function not implemented.')
}
