import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { id } from 'date-fns/locale'
import { PLANS_CLUB, USERS_TYPE } from '../../Services/contants'

interface createUserProps {
  index?: number
  name: string
  email: string
  document: string
  phone: string

}

interface User {
  name: string
  email: string
  phone: string
  document: string
  type: string
  cpf?: string
  plan: {
    plan: string
  }
}

interface OptionsProps {
  label: string
  value: string
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Preencha um e-mail válido')
    .required('E-mail é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),
  document: Yup.string().required('CPF/CNPJ é obrigatório')
})

const typeOptions = [
  {
    label: 'MyDoor House',
    value: 'HOUSE'
  },
  {
    label: 'MyDoor Club',
    value: 'CLUB'
  },
  {
    label: 'MyDoor House e Club',
    value: 'HOUSE'
  },
  {
    label: 'Nenhum tipo atribuído',
    value: 'NONE'
  }
]

const planOptions = [
  {
    label: 'ESSENCIAL',
    value: 'ESSENCIAL'
  },
  {
    label: 'EXCLUSIVE',
    value: 'EXCLUSIVE'
  },
  {
    label: 'SIGNATURE',
    value: 'SIGNATURE'
  }
]

const UsersCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [showPlan, setShowPlan] = useState(false)
  const params = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: any = useCallback(
    async (values: any) => {
      const {
        name, email, phone, document, type, plan
      } = values

      const normalizePhone = String(`${phone}`.replace(/([^0-9]+)/g, ''))

      if (normalizePhone.length < 11) {
        toast({
          title: 'Telefone inválido',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        name,
        email,
        password: '123123',
        cpf: document,
        phone,
        type,
        plan_club: plan

      }

      try {
        params?.id
          ? await api.put(`/users/${params?.id}`, payload)
          : await api.post('/users', payload)

        toast({
          title: `${params?.id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        navigate('/users')
        // router.push("/users");
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigate]
  )

  const loadItem = useCallback(async (id: string) => {
    try {
      const resp = await api.get(`/users/${String(params?.id)}`)
      const data: User = resp.data.user

      setValue('name', data.name)
      setValue('email', data.email)
      setValue('document', data.cpf)
      setValue('phone', data.phone)
      setValue('type', data.type)

      if (data.type === USERS_TYPE.CLUB || data.type === USERS_TYPE.HOUSECLUB) {
        setValue('plan', data.plan.plan)
        setShowPlan(true)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.id) {
      loadItem(params?.id)
    }
  }, [params])

  const handleSelectTypeUser = useCallback((e: string) => {
    console.log('handleSelectTypeUser: ', e)

    if (e === USERS_TYPE.CLUB || e === USERS_TYPE.HOUSECLUB) {
      setShowPlan(true)
    }
  }, [])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          // onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            {params?.id ? 'Editar Usuário' : 'Criar Usuário'}
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <Heading size="md" fontWeight="normal" color="blue.500" my="6">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Nome"
                placeholder="Preencha o nome"
                // errors={errors.name}
                {...register('name')}
              />
              <Input
                label="E-mail"
                placeholder="Preencha o e-mail do usuário"
                // errors={errors.email}
                {...register('email')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <InputMaskCustom
                type="text"
                label="CPF"
                // errors={errors.cpf}
                {...register('document')}
                placeholder="Digite o cpf"
                mask="999.999.999-99"
              />

              <InputMaskCustom
                type="text"
                label="Telefone"
                // errors={errors.phone}
                {...register('phone')}
                placeholder="Digite o telefone"
                mask="(99)99999-9999"
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                <Select
                  label="Tipo de Usuário"
                  placeholder="Selecionar Tipo"
                  errors={errors.type}
                  extraFunction={handleSelectTypeUser}
                  {...register('type')}
                  options={typeOptions}

                />

                {showPlan && (
                  <Select
                    label="Plano do Club"
                    placeholder="Selecionar plano"
                    errors={errors.plan}
                    {...register('plan')}
                    options={planOptions}
                  />
                )}
            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'gray.500' }}
                color="green.dark"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'gray.500' }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { UsersCreate }
