import axios from 'axios'

// http://localhost:21333/api
// https://blog.mydoor.com.br/api
const api = axios.create({
  baseURL: 'https://blog.mydoor.com.br/api'
})

export const adminVersion = '1.0.14'

export default api
